.container {
  position: relative;
  width: 100%;
  max-width: $size-content;
  @extend %padding-left-outer;
  @extend %padding-right-outer;
  margin: auto;
  z-index: 2;

  @media only screen and (max-width: $breakpoint-tablet) {
    &.scrollable {
      width: auto;
      max-width: none;
      padding: 0;
    }
  }

  &-fluid {
    max-width: $size-content+200px;
  }

  &-extra-fluid {
    @extend .container;
    max-width: $size-content+400px;
  }

  &-dashboard {
    @extend .container;
    max-width: $size-content+400px;
  }
}


.container-sm {
  @extend .container;
  max-width: 600px + ($size-spacing * 8);
}

.container-section {
  margin-top: 50px;
  margin-bottom: 50px;
}

.container-search {
  position: relative;
}

.container-tasks {
  .container-buttons {
    padding-top: 30px;
    margin: auto 0 10px;

    .btn-min-w {
      min-width: 180px;
    }
  }
}


.container-buttons {
  .btn-min-w {
    min-width: 230px;
  }

  button + button {
    margin-left: 15px;
  }

  &-bottom-right {
    position: absolute;
    bottom: 0;
    right: 0;
  }
}

.wrapper-tasks {
  background-color: $color-background-grey-secondary;
  margin-top: 30px;
  border-bottom-right-radius: 20px;
  padding: 15px 25px;
  flex: 1;
  display: flex;
  flex-direction: column;
}

.wrapper-articles {
  background-color: $color-background-grey-secondary;
  margin-top: 30px;
  border-bottom-right-radius: 20px;
  padding: 15px 25px;
  flex: 1;
  display: flex;
  flex-direction: column;
}

.wrapper-subjects {
  background-color: $color-background-grey-ternary;
  margin-top: 30px;
  border-bottom-right-radius: 20px;
  padding: 15px 25px;
  flex: 1;
  display: flex;
  flex-direction: column;
}

.wrapper-login {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.container-login {
  max-width: 600px;
  min-width: 500px;
  margin: auto;
  background-color: white;
  box-shadow: 2px 3px 25px 0px rgba(0, 0, 0, 0.75);
  padding-bottom: 40px;
  border-radius: 8px;

  .logo-login {
    border-radius: 50%;
    margin: auto;
    margin-bottom: 2.5rem !important;
  }

  .form-label {
    margin-bottom: 0.5rem;
    font-weight: 600;
    display: inline-block;
  }

  .form-control {
    display: block;
    width: 100%;
    padding: 0.45rem 0.9rem;
    font-size: .9rem;
    font-weight: 400;
    line-height: 1.5;
    color: #6c757d;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #dee2e6;
    margin-bottom: 1.5rem !important;
  }

  .form-check {
    display: block;
    min-height: 1.35rem;
    padding-left: 1.612em;
    margin-bottom: 0;
  }

  .text-muted {
    --bs-text-opacity: 1;
    color: #98a6ad !important;
  }

  .float-end {
    float: right !important;
  }

  .form-check-input:checked[type=checkbox] {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e");
  }

  .form-check-input:checked {
    background-color: #2C3A3E;
    border-color: #2C3A3E;
  }

  .form-check-input[type=checkbox] {
    border-radius: 0.25em;
  }

  .form-check .form-check-input {
    float: left;
    margin-left: -1.612em;
  }

  .form-check-input {
    width: 1.112em;
    height: 1.112em;
    margin-top: 0.194em;
    vertical-align: top;
    background-color: #fff;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    border: 1px solid #dee2e6;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    -webkit-print-color-adjust: exact;
    color-adjust: exact;
  }

  .span-error {
    position: absolute;
    bottom: -15px;
    color: #d51f2e;
    font-size: 10px;
    left: 0;
  }

  .required {
    position: relative;
  }

  .container-login-title {
    background-color: #263438;
    border-radius: 8px 8px 0 0;
  }

  form {
    padding: 0 40px;
  }

  button {
    margin-top: 20px;
    float: right;
  }
}

.container-full-right {
  margin-right: -50vw;
  overflow-x: visible;
  overflow-y: hidden;
  padding-left: 60px;
  margin-left: -65px;

  .container {
    margin: 0;
    padding: 0;
    max-width: 1028px;
  }
}

.container-fake-boxes {
  position: absolute;
  top: 50px;
  max-height: 490px;
  overflow: hidden;
}

.section-filters {
  margin: 15px 0;

  .col-md-7 {
    height: auto;

    .row {
      height: 100%;
    }
  }

  .container-select {
    height: 100%;

    [class$="-control"] {
      height: 100%;
    }
  }

  .btn-secondary {
    border: 1px solid #3f53595c;
  }
}

.container-benefits {
  border: 1px solid $color-border-dark-blue;
  margin: 10px 0;
  border-bottom-right-radius: 20px;
  padding: 45px 30px;

  input[type=text] + .text-danger {
    top: 0 !important;
  }

}


.container-verbal-report {
  border: 1px solid $color-border-dark-blue;
  margin: 10px 0;
  border-bottom-right-radius: 20px;
  padding: 45px 30px;

  .text-subtitle {
    &.light {
      margin-bottom: 20px;
    }
  }
}

.section-related-information {
  min-height: 500px;
}

.banner-content {
  .col-6 {
    height: auto;

    .row {
      height: 100%;
    }
  }

  .btn-primary {
    height: 100%;
  }

  .container-select {
    height: 100%;

    [class$="-control"] {
      height: 100%;
    }
  }
}

.container-shadow {
  box-shadow: 0 40px 50px 0 rgba(0, 0, 0, 0.08);
  margin: 10px 0;
  border-radius: 16px;
  padding: 40px;
  background-color: white;
}

.container-popover {
  margin: 10px 0;
  border-radius: 16px;
  padding: 20px;
  max-width: 450px;

  h3 {
    font-size: 1rem;
  }

  &-delete {
    min-width: 150px;
    margin: 13px 13px 12px;
  }
}

.container-subheader {
  display: flex;
  align-items: center;
  justify-content: space-between;

  &::after {
    content: none;
  }
}

.container-user {
  display: flex;
  align-items: center;
  position: relative;
  margin-left: auto;

  .menu-user {
    position: absolute;
    top: 100%;
    right: 0;
    background-color: white;
    padding: 10px;
    border-radius: 0 0 15px;
    border: 1px solid $color-border-blue;
    min-width: 180px;
    max-width: 180px;
    text-align: right;
    list-style-type: none;
    z-index: 1;

    &.hide {
      display: none;
    }
  }
}

.container-contextMenu {
  position: absolute;
  top: 0;
  right: 65px;

  .menu-user {
    right: -20px;
    top: calc(100% - -30px);
    font-size: 14px;
    font-weight: 500;
    text-align: left;
  }
}

.container-billing-total {
  border-top: 1px solid $color-border-dark-blue;
  padding-top: 10px;
  margin-top: auto;
}

.file-container {
  margin-bottom: 25px;

  .file-single {
    display: flex;
    font-size: 18px;
    line-height: 32px;
    color: $color-branding-blue;
    align-items: center;
    padding-bottom: 10px;
    margin-bottom: 10px;
    border-bottom: 1px solid $color-border-dark-blue-secondary;
    cursor: pointer;

    .btn-icon-delete {
      display: none;
    }

    &:hover {
      .btn-icon-delete {
        display: block;
      }
    }
  }
}

.container-favorite {
  margin-left: auto;
  display: flex;
  align-items: center;

  .counter {
    font-size: 14px;
    font-weight: 600;
    padding-right: 5px;
    line-height: 18px;
  }
}

.section-heading {
  display: flex;
  padding: 30px 0;
  margin-bottom: 30px;
}

.container-filters {
  margin-bottom: 55px;
}


.container-banner-bottom {
  padding-left: 15px;
  padding-right: 15px;
}

.container-chat {
  margin-top: 30px;

  a {
    background-position: center;

    &:last-of-type {
      margin-right: 0;
    }
  }
}