.main {
  flex: 1 0 auto;
  //padding-top: $size-header + 30px;
  padding-top: 30px;

  @media only screen and (max-width: $breakpoint-tablet) {
    padding-top: 80px;
  }

  @media only screen and (max-width: $breakpoint-mobile) {
    padding-top: 60px;
    #finder {
      padding-top: 8px;
    }
  }
}