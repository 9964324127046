.task-list-header {
  margin: 10px 0;
  padding: 20px 0 20px 0;
  align-items: center;
  border: 1px solid $color-border-dark-blue;

  .sortable {
    cursor: pointer;
  }

  & > div {
    text-align: left;
    font-size: 18px;
    font-weight: 600;
    line-height: 23px;
    color: $color-branding-grey-dark;

    &.active {
      color: $color-text-active;
    }
  }
}


.task-single {
  border: 1px solid $color-border-dark-blue;
  margin: 10px 0;
  border-bottom-right-radius: 20px;
  padding: 20px 0;
  cursor: pointer;
  align-items: center;
}

.contact-single {
  background-color: $color-background-grey-secondary;
  margin: 10px 0;
  border-bottom-right-radius: 20px;
  padding: 20px 15px;

  .d-flex {
    align-items: baseline;
  }

  &.contact-single-hoverable {
    cursor: pointer;
    border: 1px solid $color-background-grey-secondary;

    &:hover {
      background-color: $color-background-grey-ternary;
      border-color: $color-border-dark-blue-secondary;

      .btn-group-right.not-visible {
        display: block;
      }
    }
  }
}

.role-single {
  .btn-group-right {
    display: table-cell;
  }
}