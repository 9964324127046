// 00-config scss partials
@import "00-config/config";

@import "../css_external/grid.min.css";

@import "~react-toastify/scss/main";
@import "~react-datepicker/dist/react-datepicker.css";
@import "~slick-carousel/slick/slick";
@import "~slick-carousel/slick/slick-theme";
@import "~rc-collapse/assets/index.css";

// 01-components partials
@import "01-components/component";

// 02-layouts partials
@import "02-layouts/layout";