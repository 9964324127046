.slick-slide-single {
  height: 490px !important;
  height: 100%;
  position: relative;
  border-bottom-right-radius: 20px;
  overflow: hidden;
  border: 5px solid transparent;

  &:hover, &.active {
    border-color: $color-branding-light-green;
  }

  &.active {
    &:before {
      background-color: rgba(55, 55, 55, 0.75);
    }

    img {
      filter: grayscale(100%);
    }
  }

  &:before {
    position: absolute;
    content: '';
    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, .35);
    z-index: 1;
  }

  img {
    object-fit: cover;
    height: 490px;
    width: 100%;
    position: absolute;
  }

  .slide-content {
    position: relative;
    z-index: 2;
    height: 100%;
    padding: 35px 30px;
    display: flex;
    flex-direction: column;

    .slide-footer {
      margin-top: auto;
      padding-right: 25px;
      position: relative;
    }

    .btn-plus-icon {
      position: absolute;
      right: 30px;
      bottom: 30px;
    }
  }

  h4 {
    font-size: 36px;
    font-weight: bold;
    line-height: 36px;
    color: white;
    margin-bottom: 10px;
  }
}

.slick-slider {
  width: calc(100% + 5px);
  margin: 50px 0 50px -5px;
}

.slick-list {
  margin: 0 -5px;
}

.slick-slide > div {
  padding: 0 5px;
}

.slick-arrow {
  border-radius: 50%;
  background-color: $color-branding-light-green !important;
  height: 85px !important;
  width: 85px !important;
  z-index: 1;

  &.slick-prev, &.slick-next {
    &:before {
      content: '';
      background-image: url(../../../icons/chevron-vert-right.svg);
      background-size: contain;
      height: 30px;
      width: 30px;
      display: block;
      background-position: center;
      background-repeat: no-repeat;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      position: absolute;
    }
  }

  &.slick-next {
    right: -50px;
  }


  &.slick-prev {
    left: -50px;
    &:before {
      transform: rotate(-180deg) translate(50%, 50%);
    }
  }

  &.slick-disabled {
    opacity: 0;
  }
}

.slide-content-back {
  &.show {
    display: block;
  }

  &.hide {
    display: none;
  }
}