.input-text {
  border-radius: 7px;
  padding: 8px 10px;
  border: 1px solid $color-border-green;
  width: 100%;
  font-size: 16px;
  font-family: "IBM Plex Sans", sans-serif;
}

::-webkit-input-placeholder, :-moz-placeholder, ::-moz-placeholder, :-ms-input-placeholder {
  font-family: $font-primary;
  font-size: 18px;
}

.section-filters {
  .input-text {
    border-color: $color-border-dark-blue;
  }

  .btn-primary {
    height: 100%;
  }
}

.invalid-feedback {
  position: absolute;
  font-size: 13px;
}

.tenancy-container {
  .label-component {
    margin-bottom: 0;
  }

  .text-muted {
    margin-bottom: 15px;
    display: inline-block;
  }
}