.list-tasks {
  li {
    border-bottom: 1px solid $color-border-dark-blue;
    padding: 15px 0;
    margin: 0;
  }
}

.list-events {
  .read-more {
    display: none;
    align-items: flex-end;
  }

  .avatar-group {
    margin-right: 15px;
  }

  li {
    cursor: pointer;
    border: 1px solid transparent;
    background-color: white;
    padding: 25px;
    border-radius: 0 0 15px;
    margin: 15px 0;

    &:hover {
      border-color: $color-border-blue;

      .read-more {
        display: flex;
      }
    }
  }

}

.list-tasks, .list-events {
  max-height: 420px;
  min-height: 420px;
  overflow-y: hidden;
}

.list-pie {
  list-style-type: none;

  li {
    display: flex;
    align-items: center;
    margin-bottom: 10px;

    &.last-of-type {
      margin-bottom: 0;
    }
  }

  .list-pie-icon {
    height: 15px;
    width: 15px;
    border-radius: 50%;
    display: inline-block;
    margin-right: 10px;

    &.one {
      background-color: #99D7E4;
    }

    &.two {
      background-color: #BDEDF7;
    }

    &.three {
      background-color: #99BEC6;
    }

    &.four {
      background-color: #6B8186;
    }

    &.five {
      background-color: #3E4C4F;
    }

  }

  a {
    &:hover {
      text-decoration: underline;
      color: $color-branding-blue;
    }
  }

  .text-blue {
    margin-left: auto;
  }
}

.list-billing {
  list-style-type: none;
  padding-bottom: 10px;

  li {
    margin-bottom: 10px;

    &:last-of-type {
      margin-bottom: 0;
    }
  }
}

.list-timeline {
  list-style-type: none;

  li {
    padding-bottom: 30px;

    &:last-of-type {
      .badge-secondary {
        &:after {
          content: none;
        }
      }
    }
  }

  .badge-secondary {
    font-size: 14px;
    color: $color-branding-grey;
    padding: 8px 18px;

    position: relative;

    &:after {
      position: absolute;
      content: '';
      top: 35px;
      left: 50%;
      transform: translateX(-50%);
      height: calc(100% + 30px);
      width: 1px;
      background-color: $color-background-grey-secondary;
    }
  }
}