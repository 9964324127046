.table-primary {
  table-layout: fixed;

  thead {
    border-bottom: 1px solid $color-border-dark-blue;

    th {
      text-align: left;
      font-size: 18px;
      font-weight: 600;
      line-height: 23px;
      color: $color-branding-grey-dark;
      padding: 10px 0;
    }
  }

  tbody {
    tr {
      border-bottom: 1px solid $color-border-dark-blue;

      td {
        padding: 15px 0;
      }
    }
  }

  .table-summary {
    border-bottom: none;
    border-top: 2px solid $color-border-dark-blue;
  }
}


.infinite-scroll-component {
  overflow: hidden !important;
}