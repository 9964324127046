.img-avatar {
  height: 60px;
  width: 60px;
  border-radius: 50%;
  margin: 0 5px;
  cursor: pointer;

  &.border {
    border: 2px solid $color-branding-blue-lightest;
    background-color: white;
  }
}

.img-profile {
  height: 140px;
  width: 140px;
  border-radius: 50%;
  margin: 0 10px;
  border: 5px solid $color-branding-dark-green;
  object-fit: cover;
}