.comment-single {
    display: flex;

    .comment-title {
        display: flex;
        align-items: center;
        padding: 10px 0;
    }

    .img-avatar {
        height: 60px;
        width: 60px;
        margin-left: 0;
        margin-right: 20px;
    }

    .title-container, .img-avatar {
        display: inline-block;
    }

    .title-name {
        font-size: 16px;
        font-weight: 600;
        line-height: 20px;
        color: $color-branding-grey;
    }

    .title-date {
        font-size: 14px;
        font-weight: 600;
        line-height: 20px;
        color: $color-branding-green;
    }

    .comment-body {
        font-size: 16px;
        padding-right: 60px;
        line-height: 28px;
        color: $color-branding-blue;
    }

    .comment-footer {
        margin: 15px 0;
        display: flex;
    }

    .comment-reply {
        position: relative;
        padding-left: 40px;

        &:before {
            position: absolute;
            content: '';
            background-image: url(../../../icons/icon-reply-green.svg);
            margin-right: 5px;
            left: 0;
            top: 20px;
            height: 17px;
            width: 22px;
            background-size: cover;
        }
    }
}