.message-success {
    font-size: 14px;
    color: $color-message-success;
    line-height: 20px;
}

.message-error {
    font-size: 14px;
    color: $color-message-error;
    line-height: 20px;
}