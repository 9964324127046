.sidebar {
  bottom: 0;
  margin-top: 0;
  position: fixed;
  top: 0;
  width: $sidebar-width;
  z-index: 1001;
  background: $color-branding-light-grey;
  padding-top: 150px;
  padding-bottom: $size-header;
  display: grid;
  align-content: center;
  justify-items: center;

  &-branding, &-settings, &-text {
    position: fixed;
    z-index: 99;
  }

  &-branding {
    top: 0;
    padding: 1.25rem 1.5rem 0 1.5rem;
  }

  &-settings {
    color: white;
    bottom: 0;
    padding: 0 1.5rem 2.25rem 1.5rem;

    &-img {
      width: 40px;
      height: 40px;
      cursor: pointer;
    }
  }

  &-text {
    color: white;
    bottom: 0;
    padding-bottom: 0.75rem;
  }

  &-user {
    position: fixed;
    z-index: 99;
    padding: 1rem .35rem;
    text-align: center;
    top: 70px;
    width: 92px;

    a {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }

  &-menu {
    display: flex;
    justify-content: center;

    ul {
      display: flex;
      flex-direction: column;
      list-style: none;
    }

    li {
      display: block;
      position: relative;
      font-size: 13px;
      transition: all 0.4s;
    }
  }


  @media (max-width: $breakpoint-tablet) {
    display: none;
  }
}

.quick-btns {
  position: fixed;
  bottom: 50%;
  transform: translateY(50%);
  right: 50px;
  display: flex;
  flex-direction: column;
  z-index: 2;

  button + button {
    margin-top: 20px;
  }
}