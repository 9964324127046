.authentication-footer {
  p {
    font: $font-primary;
    color: $color-grey-dark;
    font-size: 12px;
    font-weight: 400;

    a {
      color: inherit;
    }
  }

}