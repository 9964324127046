#header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 10;
  background-color: white;

  &-content {
    display: flex;
    align-items: flex-start;
  }

  &-menu {
    display: flex;
    align-items: center;
    padding-bottom: 5px;
    width: 100%;
    position: relative;

    &:after {
      position: absolute;
      content: '';
      left: 0;
      bottom: 0;
      background-color: $color-border-green;
      height: 1px;
      width: calc(100vw / 2 + 512px);
    }
  }

  &:before {
    display: block;
    width: 100%;
    height: $size-header-stripe;
    background: $color-branding-green;
    content: '';
  }

  .header-menu {
    ul {
      display: flex;
      list-style: none;
    }

    li {
      display: flex;
      padding-left: 20px;
      padding-top: $size-header-stripe;
      transition: all .15s ease-in-out;
      background-color: white;
      border-radius: 0 0 15px;

      a {
        background-size: 20px;
        background-repeat: no-repeat;
        padding-left: 30px;
        background-position: left;
        color: inherit;
        text-transform: capitalize;
      }

      &.home {
        a {
          background-image: url("../../../../assets/icons/icon-house.svg");
        }
      }

      &.projects {
        a {
          background-image: url("../../../../assets/icons/icon-plan.svg");
        }
      }

      &.contact {
        a {
          background-image: url("../../../../assets/icons/icon-contact.svg");
        }
      }

      &.tasks {
        a {
          background-image: url("../../../../assets/icons/icon-task.svg");
        }
      }

      &.timesheet {
        a {
          background-image: url("../../../../assets/icons/icon-timesheets.svg");
        }
      }

      &.timeline {
        a {
          background-image: url("../../../../assets/icons/icon-time-black.svg");
        }
      }

      &.events {
        a {
          background-image: url("../../../../assets/icons/icon-calendar-black.svg");
        }
      }

      &.invoicing {
        a {
          background-image: url("../../../../assets/icons/icon-money-black.svg");
        }
      }

      &.logout {
        background: none;

        &.active, &:hover {
          background: none;
        }
      }

      &.active, &:hover {
        background-color: $color-branding-green;
        border-radius: 0 0 15px;
      }
    }

    a {
      display: flex;
      position: relative;
      white-space: nowrap;
      align-items: center;
      padding: 20px 25px 20px 0;
      cursor: pointer;
      font-size: 18px;
    }

    @media only screen and (max-width: $breakpoint-tablet) {
      display: none;
    }
  }


  .header-profile-menu {

    position: relative;
    max-width: 100%;
    text-align: center;
    z-index: 200;

    .down {
      background-image: url(../../../icons/chevron-vert.svg);
      width: 20px;
      height: 20px;
      margin: 0 10px;
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;
    }

    .sec-center {
      display: flex;
      position: relative;
      white-space: nowrap;
      align-items: center;
      width: 200px;
      padding: 20px 25px 20px 0;
      cursor: pointer;
      font-size: 18px;
    }

    [type="checkbox"]:checked,
    [type="checkbox"]:not(:checked) {
      position: absolute;
      left: -9999px;
      opacity: 0;
      pointer-events: none;
    }

    .dropdown:checked + label,
    .dropdown:not(:checked) + label {
      font-family: 'Roboto', sans-serif;
      font-weight: 500;
      font-size: 18px;
      transition: all 200ms linear;
      border-radius: 4px;
      display: -webkit-inline-flex;
      display: -ms-inline-flexbox;
      display: flex;
      border: none;
      cursor: pointer;
    }

    .dropdown:checked + label:before,
    .dropdown:not(:checked) + label:before {
      position: fixed;
      top: 0;
      left: 0;
      content: '';
      width: 100%;
      height: 100%;
      z-index: -1;
      cursor: auto;
      pointer-events: none;
    }

    .dropdown:checked + label:before {
      pointer-events: auto;
    }

    .dropdown:not(:checked) + label .uil {
      font-size: 24px;
      margin-left: 10px;
      transition: transform 200ms linear;
    }

    .dropdown:checked + label .uil {
      transform: rotate(180deg);
      font-size: 24px;
      margin-left: 10px;
      transition: transform 200ms linear;
    }

    .section-dropdown {
      position: absolute;
      padding: 5px;
      background-color: #FFFFFF;
      top: 70px;
      left: 0;
      width: 100%;
      border-radius: 4px;
      display: block;
      box-shadow: 0 14px 35px 0 rgba(9, 9, 12, 0.4);
      z-index: 2;
      opacity: 0;
      pointer-events: none;
      transform: translateY(20px);
      transition: all 200ms linear;
    }

    .dropdown:checked ~ .section-dropdown {
      opacity: 1;
      pointer-events: auto;
      transform: translateY(0);
    }

    .section-dropdown:before {
      position: absolute;
      top: -20px;
      left: 0;
      width: 100%;
      height: 20px;
      content: '';
      display: block;
      z-index: 1;
    }

    .section-dropdown:after {
      position: absolute;
      top: -7px;
      left: 30px;
      width: 0;
      height: 0;
      border-left: 8px solid transparent;
      border-right: 8px solid transparent;
      border-bottom: 8px solid #FFFFFF;
      content: '';
      display: block;
      z-index: 2;
      transition: all 200ms linear;
    }

    a {
      position: relative;
      color: #fff;
      transition: all 200ms linear;
      font-family: 'Roboto', sans-serif;
      font-weight: 500;
      font-size: 15px;
      border-radius: 2px;
      padding: 5px 15px 5px 20px;
      margin: 2px 0;
      text-align: left;
      text-decoration: none;
      display: -ms-flexbox;
      display: flex;
      -webkit-align-items: center;
      -moz-align-items: center;
      -ms-align-items: center;
      align-items: center;
      justify-content: space-between;
      -ms-flex-pack: distribute;
    }

    a:hover {
      background-color: #CCE004;
    }

    a .uil {
      font-size: 22px;
    }

    .dropdown-sub:checked + label,
    .dropdown-sub:not(:checked) + label {
      position: relative;
      color: #fff;
      transition: all 200ms linear;
      font-family: 'Roboto', sans-serif;
      font-weight: 500;
      font-size: 15px;
      border-radius: 2px;
      padding: 5px 0;
      padding-left: 20px;
      padding-right: 15px;
      text-align: left;
      text-decoration: none;
      display: -ms-flexbox;
      display: flex;
      -webkit-align-items: center;
      -moz-align-items: center;
      -ms-align-items: center;
      align-items: center;
      justify-content: space-between;
      -ms-flex-pack: distribute;
      cursor: pointer;
    }

    .dropdown-sub:checked + label .uil,
    .dropdown-sub:not(:checked) + label .uil {
      font-size: 22px;
    }

    .dropdown-sub:not(:checked) + label .uil {
      transition: transform 200ms linear;
    }

    .dropdown-sub:checked + label .uil {
      transform: rotate(135deg);
      transition: transform 200ms linear;
    }

    .dropdown-sub:checked + label:hover,
    .dropdown-sub:not(:checked) + label:hover {
      color: #102770;
      background-color: #ffeba7;
    }

    .section-dropdown-sub {
      position: relative;
      display: block;
      width: 100%;
      pointer-events: none;
      opacity: 0;
      max-height: 0;
      padding-left: 10px;
      padding-right: 3px;
      overflow: hidden;
      transition: all 200ms linear;
    }

    .dropdown-sub:checked ~ .section-dropdown-sub {
      pointer-events: auto;
      opacity: 1;
      max-height: 999px;
    }

    .section-dropdown-sub a {
      font-size: 14px;
    }

    .section-dropdown-sub a .uil {
      font-size: 20px;
    }

    .logo {
      position: fixed;
      top: 50px;
      left: 40px;
      display: block;
      z-index: 11000000;
      background-color: transparent;
      border-radius: 0;
      padding: 0;
      transition: all 250ms linear;
    }

    .logo:hover {
      background-color: transparent;
    }

    .logo img {
      height: 26px;
      width: auto;
      display: block;
      transition: all 200ms linear;
    }

    @media screen and (max-width: 991px) {
      .logo {
        top: 30px;
        left: 20px;
      }
    }
  }
}
