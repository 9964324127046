.w-100 {
  width: 100%;
}

.w-50 {
  width: 50%;
}

.w-25 {
  width: 25%;
}

.w-20 {
  width: 20%;
}

.w-15 {
  width: 15%;
}
.w-30 {
  width: 30%;
}

.w-35 {
  width: 35%;
}

.w-5 {
  width: 5%;
}

.w-10 {
  width: 10%;
}

.d-flex {
  display: flex;
}

.flex-column {
  flex-direction: column;
}

.mt-10 {
  margin-top: 10px;
}

.mt-15 {
  margin-top: 15px;
}

.mt-35 {
  margin-top: 35px;
}

.mb-15 {
  margin-bottom: 15px;
}

.mt-40 {
  margin-top: 40px;
}

.mb-20 {
  margin-bottom: 20px;
}

.mb-25 {
  margin-bottom: 25px;
}

.mb-30 {
  margin-bottom: 30px;
}

.mb-35 {
  margin-bottom: 35px;
}

.mb-0 {
  margin-bottom: 0;
}

.ml-15 {
  margin-left: 15px;
}

.ml-min-40 {
  margin-left: -40px;
}

.mt-5p {
  margin-top: 5px;
}

.mb-5p {
  margin-bottom: 5px;
}

.pl-0 {
  padding-left: 0;
}

.pb-25 {
  padding-bottom: 25px;
}

.visibility-none {
  visibility: hidden;
}

.align-flex-end {
  align-items: flex-end;
}

.text-right {
  text-align: right;
}

.flex-center {
  align-items: center;
}

.border-bottom {
  border-bottom: 1px solid $color-border-dark-blue;
}

.mb-empty {
  height: 50px;
}

.border-right {
  border-right: 1px solid $color-border-dark-blue;
}

.pl-45 {
  padding-left: 45px;
}

.pl-15 {
  padding-left: 15px;
}

.pr-30 {
  padding-right: 30px;
}

.pl-30 {
  padding-left: 30px;
}

.pr-45 {
  padding-right: 45px;
}

.max-height-150 {
  max-height: 160px;
}

.text-right {
  text-align: right;
}

.max-w-180 {
  max-width: 180px;
}

.justify-between {
  justify-content: space-between;
}

.justify-end {
  justify-content: flex-end;
}

.d-flex-column {
  display: flex;
  flex-direction: column;
}
