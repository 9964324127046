.section-banner {
  .container-banner-text {
    background-color: $color-background-grey-secondary;
    padding: 65px 45px;
  }
}

.container-banner-img {
  margin-left: -50px;

  .banner-img {
    height: 400px;
    width: 100%;
    border-top-left-radius: 20px;
    overflow: hidden;

    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }

  .banner-text {
    background-color: $color-branding-green;
    padding: 25px;
    border-bottom-left-radius: 20px;

    &.no-border {
      border-bottom-left-radius: 0;
    }
  }

  .banner-text-secondary {
    background-color: $color-branding-light-grey;
    color: white;
    border-bottom-right-radius: 20px;
    display: flex;
    align-items: center;


    .text-md {
      flex: 1;
    }
  }
}

.section-navigations {
  padding-bottom: 5px;
  margin: 15px 0;

  .navigation-menu {

    ul {
      display: flex;
      list-style: none;
      justify-content: center;
    }

    li {
      flex-grow: 1;
      display: flex;
      padding-left: 20px;
      padding-top: $size-header-stripe;
      transition: all .15s ease-in-out;
      background-color: $color-brand-green-bright;

      a {
        background-size: 20px;
        background-repeat: no-repeat;
        padding-left: 30px;
        background-position: left;
        color: inherit;
      }

      &.projects {
        a {
          background-image: url("../../../../assets/icons/icon-plan.svg");
        }
      }

      &.contact {
        a {
          background-image: url("../../../../assets/icons/icon-contact.svg");
        }
      }

      &.tasks {
        a {
          background-image: url("../../../../assets/icons/icon-task.svg");
        }
      }

      &.timesheet {
        a {
          background-image: url("../../../../assets/icons/icon-time-black.svg");
        }
      }

      &.events {
        a {
          background-image: url("../../../../assets/icons/icon-calendar-black.svg");
        }
      }

      &.invoicing {
        a {
          background-image: url("../../../../assets/icons/icon-money-black.svg");
        }
      }

      &.logout {
        background: none;

        a {
          font-size: 16px;
        }

        &.active, &:hover {
          background: none;
        }
      }

      &.active, &:hover {
        background-color: $color-branding-green;
      }
    }

    a {
      display: flex;
      position: relative;
      white-space: nowrap;
      align-items: center;
      padding: 20px 25px 20px 0;
      cursor: pointer;
      font-size: 18px;
    }
  }
}
