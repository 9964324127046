.progress-bar {
  padding-top: 60px;
  padding-bottom: 30px;
  float: none;
  margin-left: -70px;
  width: calc(100% + 140px);
  position: relative;


  &-border-bottom {

    &:after {
      position: absolute;
      content: '';
      border-bottom: 1px solid $color-border-dark-blue;
      width: calc(100% - 200px);
      left: 100px;
      height: 1px;
      bottom: 0;
    }
  }

  ul {
    display: flex;
    list-style-type: none;

    li {
      flex: 1;
      position: relative;

      &:before,
      &:after {
        position: absolute;
        content: "";
        top: -18px;
        left: 0;
        width: calc(50% - 7px);
        background-color: white;
        height: 5px;
      }

      &:after {
        right: 0;
        left: inherit;
      }

      &:first-of-type {
        &:before {
          width: 0;
        }
      }

      &:last-of-type {
        &:after {
          width: 0;
        }

        a {
          &:before {
            position: absolute;
            top: -23px;
            left: 50%;
            content: "";
            transform: translateX(-50%);
            height: 15px;
            width: 15px;
            background: white;
            border: 1px solid white;
            border-radius: 50%;
          }

          .progress-bar-content {
            position: relative;
            left: -25%;
          }
        }
      }

      a {
        // position: relative;
        font-size: 11px;
        color: #333333;
        text-transform: uppercase;
        padding-left: 45%;

        .progress-bar-content {
          font-size: 10px;
          text-transform: uppercase;
          font-weight: bold;
          margin-left: 5px;
          display: inline-block;

          .progress-bar-title {
            color: $color-branding-grey-dark;
            display: block;
          }

          .progress-bar-date {
            color: $color-branding-blue-light;
            display: block;

            &-orange {
              color: #FF5100;
            }

            &-red {
              color: #DC1212;
            }
          }

        }

        &:before {
          position: absolute;
          top: -23px;
          left: 50%;
          content: "";
          transform: translateX(-50%);
          height: 15px;
          width: 15px;
          background: white;
          border: 1px solid white;
          border-radius: 50%;
        }

        &.disabled {
          pointer-events: none;
        }
      }

      &.active {
        &:after {
          background-color: $color-branding-green;
        }

        &:before {
          background-color: $color-branding-green;
          height: 5px;
          right: 50%;
          left: inherit;
          width: calc(100% - 10px);
        }

        a {
          &:before {
            background-image: url(../../../icons/icon-bullet-check.svg);
            background-size: 15px;
            background-position: center;
            background-repeat: no-repeat;
            width: 15px;
            height: 15px;
            border: 0;
            top: -23px;
            z-index: 1;
            background-color: transparent;
          }
        }

        &:first-of-type {
          &:before {
            width: 0;
          }
        }
      }
    }
  }
}
