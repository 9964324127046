// PADDINGS

%padding {

  // Regular

  &-top-regular {
    padding-top: $size-spacing;
  }

  &-left-regular {
    padding-left: $size-spacing;
  }

  &-right-regular {
    padding-right: $size-spacing;
  }

  &-bottom-regular {
    padding-bottom: $size-spacing;
  }

  // Outer

  &-top-outer {
    padding-top: $size-spacing * 4;
  }

  &-left-outer {
    padding-left: $size-spacing * 4;
  }

  &-right-outer {
    padding-right: $size-spacing * 4;
  }

  &-bottom-outer {
    padding-bottom: $size-spacing * 4;
  }

  // Large

  &-top-large {
    padding-top: $size-spacing * 8;

    @media only screen and (max-width: $breakpoint-tablet) {
      padding-top: $size-spacing * 4;
    }
  }

  &-left-large {
    padding-left: $size-spacing * 8;

    @media only screen and (max-width: $breakpoint-tablet) {
      padding-left: $size-spacing * 4;
    }
  }

  &-right-large {
    padding-right: $size-spacing * 8;

    @media only screen and (max-width: $breakpoint-tablet) {
      padding-right: $size-spacing * 4;
    }
  }

  &-bottom-large {
    padding-bottom: $size-spacing * 8;

    @media only screen and (max-width: $breakpoint-tablet) {
      padding-bottom: $size-spacing * 4;
    }
  }
}

// MARGINS

%margin {

  // Regular

  &-left-regular-negative {
    margin-left: -$size-spacing;
  }

  &-right-regular-negative {
    margin-right: -$size-spacing;
  }

  // Large

  &-top-large-negative {
    margin-top: -($size-spacing * 8);
  }

  &-bottom-large-negative {
    margin-bottom: -($size-spacing * 8);
  }
}