.form {
  textarea, .container-date, .container-text {
    margin-bottom: 20px;
  }

  .container-select + .text-danger {
    position: relative;
    top: -15px;
  }

  .container-select.is-invalid {
    [class$=-control] {
      border-color: #dc3545 !important;
    }

    [class$=-ValueContainer] > div {
      color: #dc3545 !important;
    }
  }

  input[type="text"], textarea, input[type="password"], input[type="email"], input[type="number"] {
    &.is-invalid {
      border-color: #dc3545 !important;
    }
  }

  input[type="text"] + .text-danger {
    position: relative;
    top: -15px;
  }

  .container-date + .text-danger {
    position: relative;
    top: -15px;
  }

  textarea + .text-danger {
    position: relative;
    top: -15px;
  }
}

.form-heading {
  margin-bottom: 20px;

  .btn-close {
    position: absolute;
    right: 0;
    top: 0;
  }
}

.form-heading-bg {
  background-color: $color-branding-green;
  margin-left: -40px;
  margin-top: -40px;
  flex: 0 0 calc(100% + 80px);
  max-width: calc(100% + 80px);
  background-image: url(../../../icons/synapp-picto-white.svg);
  background-repeat: no-repeat;
  background-position: 90% 35%;
  background-size: 28%;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  display: flex;
  align-items: center;
  min-height: 125px;

  h3 {
    margin-bottom: 0 !important;
    padding: 0 40px;
  }
}

.form-body {
  padding-top: 35px;

  .text-bold {
    margin-bottom: 10px;
  }

  .text-subtitle {
    margin-bottom: 5px;

    &.light {
      margin-bottom: 30px;
    }
  }

  input[type="text"] {
    color: $color-grey-dark;
  }

  .container-checkbox label {
    color: $color-branding-blue;
  }
}

.form-profile {
  align-items: center;
  display: flex;
  flex-direction: column;
  padding: 40px 0;
  border-bottom: 1px solid $color-border-dark-blue;

  h3 {
    margin-top: 30px;
  }

  h4 {
    color: $color-branding-blue;
    font-weight: 300;
  }
}

.form-heading {
  .btn-close {
    position: absolute;
    right: 0;
    top: 0;
  }
}

.verbal-report-template-form {
  .container-shadow {
    max-height: 650px;
  }
}