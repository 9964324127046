.container-checkbox {
    input {
        padding: 0;
        height: initial;
        width: initial;
        margin-bottom: 0;
        display: none;
        cursor: pointer;

        &:checked {
            + label {
                &:after {
                    content: "\2713";
                    display: block;
                    position: absolute;
                    top: 4px;
                    left: 3px;
                    font-size: 12px;
                    font-weight: bold;
                    color: $color-grey-dark;
                }
            }
        }
    }

    label {
        position: relative;
        cursor: pointer;

        &:before {
            content: "";
            -webkit-appearance: none;
            background-color: transparent;
            border: 2px solid $color-grey-dark;
            border-radius: 2px;
            padding: 6px;
            display: inline-block;
            position: relative;
            vertical-align: middle;
            cursor: pointer;
            margin-right: 5px;
        }
    }
}