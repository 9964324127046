.icon {
  height: 20px;
  width: 20px;
  background-size: cover;
  background-repeat: no-repeat;
  display: inline-block;
  margin-right: 15px;
}

.icon-tasks {
  @extend .icon;
  background-image: url(../../../icons/icon-calendar-green.svg);
}

.icon-check {
  @extend .icon;
  background-image: url(../../../icons/icon-task-green.svg);
}

.icon-plan-green {
  @extend .icon;
  background-image: url(../../../icons/icon-plan-green.svg);
}

.icon-place {
  @extend .icon;
  margin-right: 5px;
  height: 15px;
  width: 15px;
  background-image: url(../../../icons/icon-place.svg);
}

.icon-print {
  @extend .icon;
  background-image: url(../../../icons/icon-print.svg);
  background-size: contain;
  margin: auto;
}

.icon-document-green {
  @extend .icon;
  background-image: url(../../../icons/icon-document-green.svg);
  margin-right: 10px;
  background-size: contain;
}

.icon-chat {
  @extend .icon;
  background-image: url(../../../icons/icon-chat.svg);
  background-size: contain;
  margin: auto;
}

.icon-flag {
  @extend .icon;
  margin-right: 5px;
  height: 12px;
  width: 12px;
  background-image: url(../../../icons/icon-flag.svg);

  &.red {
    background-color: red;
    -webkit-mask-image: url(../../../icons/icon-flag.svg);
    mask-image: url(../../../icons/icon-flag.svg);
    background-image: none;
  }

  &.orange {
    background-color: orange;
    -webkit-mask-image: url(../../../icons/icon-flag.svg);
    mask-image: url(../../../icons/icon-flag.svg);
    background-image: none;
  }

  &.cyan {
    background-color: cyan;
    -webkit-mask-image: url(../../../icons/icon-flag.svg);
    mask-image: url(../../../icons/icon-flag.svg);
    background-image: none;
  }

  &.yellow {
    background-color: yellow;
    -webkit-mask-image: url(../../../icons/icon-flag.svg);
    mask-image: url(../../../icons/icon-flag.svg);
    background-image: none;
  }

  &.green {
    background-color: green;
    -webkit-mask-image: url(../../../icons/icon-flag.svg);
    mask-image: url(../../../icons/icon-flag.svg);
    background-image: none;
  }
}

.icon-time-green {
  @extend .icon;
  margin-right: 5px;
  height: 12px;
  width: 12px;
  background-image: url(../../../icons/icon-time-green.svg);
}

.icon-bullet-check {
  @extend .icon;
  margin-right: 5px;
  height: 15px;
  width: 15px;
  background-image: url(../../../icons/icon-bullet-check.svg);
}

.icon-bullet-stop {
  @extend .icon;
  margin-right: 5px;
  height: 15px;
  width: 15px;
  background-image: url(../../../icons/icon-bullet-stop.svg);
}

.icon-arrow-blue {
  @extend .icon;
  background-image: url(../../../icons/icon-arrow-short-right-blue.svg);
  margin-right: 5px;
  height: 10px;
  width: 10px;
  background-size: contain;
}

.icon-document-black {
  @extend .icon;
  background-image: url(../../../icons/icon-document-black.svg);
  height: 26px;
  width: 19px;
  background-size: contain;
}

.icon-timesheets-white {
  @extend .icon;
  background-image: url(../../../icons/icon-timesheets-white-old.svg);
  background-size: contain;
  height: 34px;
  width: 34px;
  margin-right: 0;
}

.icon-chat-green {
  @extend .icon;
  background-image: url(../../../icons/icon-chat-green.svg);
  background-size: contain;
  margin-right: 30px;
}

.icon-mail-green {
  @extend .icon;
  background-image: url(../../../icons/icon-mail-green.svg);
  background-size: contain;
  margin-right: 30px;
}

.icon-friend-green {
  @extend .icon;
  background-image: url(../../../icons/icon-friend-green.svg);
  background-size: contain;
  margin-right: 30px;
}

.icon-phone-green {
  @extend .icon;
  color: $color-text-active;
  margin-right: 30px;
  margin-left: -3px;
}

.container-chat {
  .icon-phone-green, .icon-chat-green, .icon-mail-green {
    width: 1em;
    height: 1em;
    font-size: 1.5rem;
  }
}