.Collapsible {
  position: relative;

  .Collapsible__trigger {
    display: block;
    cursor: pointer;

    &.is-open, &.is-closed {
      position: relative;

      &:after {
        position: absolute;
        content: '';
        right: 0;
        mask: url(../../../icons/chevron-vert.svg);
        mask-repeat: no-repeat;
        background-color: $color-grey-dark;
        width: 20px;
        height: 20px;
        top: 50%;
        transform: translateY(calc(-50% + 5px));
        transition: all .3s ease-in-out;
      }

      &.is-closed {
        &:after {
          transform: translateY(calc(-50% - 5px)) rotate(180deg);
        }
      }
    }
  }
}

.rc-collapse > .rc-collapse-item:first-child {
  border: 1px solid #d9d9d9;
}

.rc-collapse > .rc-collapse-item-disabled > .rc-collapse-header {
  cursor: auto;
}

.collapse {

  background-color: initial;
  border: none;

  .rc-collapse-item-disabled {
    .rc-collapse-header-text {
      &:after {
        content: none !important;
      }
    }
  }

  .rc-collapse-item {
    display: block;
    cursor: pointer;
    position: relative;

    background-color: #f7f7f7;
    border-radius: 3px;
    border: 1px solid #d9d9d9;
    margin-bottom: 25px;

    textarea {
      min-height: 450px;
    }

    select {
      z-index: 999999;
    }

    .rc-collapse-header-text {
      display: flex;
      position: relative;
      width: 100%;
      padding-right: 15px;
      padding-left: 15px;

      .last-article-title {
        padding: 8px 10px;
      }

      .button-container {
        display: flex;
        position: relative;
        width: 100%;
        height: 100%;
        justify-content: flex-end;
        align-items: center;
      }

      &:after {
        position: absolute;
        content: '';
        right: 0;
        mask: url(../../../icons/chevron-vert.svg);
        mask-repeat: no-repeat;
        background-color: $color-grey-dark;
        width: 20px;
        height: 20px;
        top: 50%;
        transform: translateY(calc(-50% - 5px)) rotate(180deg);
        transition: all .3s ease-in-out;
      }

      .container-text {
        margin-bottom: 0;
        display: inline-block;

        .input-text {
          margin-left: 15px;
          width: 90%;
        }
      }
    }

    &-active {
      .rc-collapse-header-text {
        &:after {
          transform: translateY(calc(-50% + 5px));
        }
      }
    }
  }
}