.textarea {
  min-height: 128px;
  border: 1px solid $color-border-dark-blue;
  border-radius: 8px;
  background-color: #FFFFFF;
  padding: 15px;
  resize: none;
  width: 100%;
  font-family: $font-primary;
  font-size: 16px;

  &.md {
    min-height: 290px;
  }
}

textarea[disabled] {
  background-color: rgba(239, 239, 239, 0.3);
  color: rgb(84, 84, 84);
  cursor: default;
  border-color: rgba(118, 118, 118, 0.3);
}