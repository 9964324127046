.banner-container {
    background-color: $color-branding-green;
    margin-right: calc(-100vw / 2 + 512px);
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;

    .container {
        margin: 0;
        padding: 25px 40px;
        max-width: $size-content - 20px;
    }

    .banner-img {
        background-image: url(../../../images/office-space.jpg);
        min-height: 200px;
        background-size: cover;
        background-position: center;
        position: relative;
        display: flex;
        border-top-left-radius: 20px;

        .container {
            margin-top: auto;
        }

        &:before {
            position: absolute;
            content: '';
            height: 100%;
            width: 100%;
            background-color: rgba(0,0,0,.45);
            border-top-left-radius: 20px;
        }
    }

    .banner-headings {
        display: flex;
        margin-bottom: 20px;

        h2 {
            flex: 1;
            color: $color-branding-light-green;
            font-size: 36px;
            letter-spacing: -0.45px;
            line-height: 34px;

            + h2 {
                color: white;
                text-align: right;
                font-size: 34px;
                font-weight: bold;
                letter-spacing: -0.41px;
                line-height: 32px;
            }
        }
    }
}