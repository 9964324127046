.container-select {
  font-size: 16px;
  color: $color-branding-blue;
  letter-spacing: -0.6px;
  width: auto;
  min-width: 180px;
  display: inline-block;
  //z-index: 3;

  [class$="-control"] {
    border-color: $color-border-dark-blue;
    border-radius: 8px;
  }

  [class$="-ValueContainer"] {
    padding: 4px 10px;
  }

  [class$="-indicatorSeparator"] {
    display: none;
  }

  [class$="-option"] {
    background-color: transparent;
    color: $color-branding-blue;

    &:hover {
      background-color: $color-background-grey;
    }
  }

  [class$="-indicatorContainer"]:last-child {
    background-image: url(../../../icons/chevron-vert.svg);
    width: 20px;
    height: 20px;
    margin-right: 10px;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;

    svg {
      display: none;
    }
  }
}

.wrapper-select {
  margin-top: 40px;
  position: relative;
  z-index: 3;

  .container-select {
    margin-right: 10px;
  }
}