h1 {
  font-size: 45px;
  color: $color-branding-grey;
  margin-bottom: 25px;
}

h2 {
  font-size: 28px;
  font-weight: 500;

  &.section-title {
    font-size: 32px;
    font-weight: 600;
    line-height: 42px;
    color: $color-branding-grey-dark;
  }
}

h3 {
  font-size: 25px;
  color: $color-branding-blue;
  display: flex;
  align-items: center;
  font-weight: 600;
}

h4 {
  font-size: 18px;
  font-weight: 600;
  line-height: 23px;
  color: $color-branding-grey-dark;
}

h5 {
  color: $color-branding-grey-dark;
  font-size: 12px;
  font-weight: 600;
  line-height: 15px;
  text-transform: uppercase;
  padding: 20px 0;
}