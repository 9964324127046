.breadcrumbs {
  list-style-type: none;
  margin: 20px 0;
  //float: left;

  li {
    display: inline-block;
    position: relative;
    padding-right: 25px;
    margin-right: 15px;

    &:last-child {
      padding-right: 0;
      margin-right: 0;
    }

    a {
      font-size: 14px;
      color: $color-branding-grey;
      line-height: 18px;
    }

    &:after {
      position: absolute;
      right: 0;
      top: 50%;
      content: '';
      background-image: url(../../../icons/chevron-vert.svg);
      background-position: center;
      background-repeat: no-repeat;
      transform: translateY(calc(-50% + 2px)) rotate(-90deg);
      width: 10px;
      height: 10px;
    }

    &:last-of-type {
      &:after {
        background-image: none;
      }
    }
  }
}