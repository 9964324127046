.btn-close-modal {
  background: transparent;
  border: 0;
  font-size: 10px;
  padding: 1.35rem 1.25rem;
  position: absolute;
  top: 0;
  right: 0;
  opacity: 0.5;
  color: $color-branding-second-light-grey;
}

.modal {
  inset: 50% auto auto 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  border: 0;
  background: none;
  overflow: visible;
  position: absolute;
  border-radius: 4px;
  outline: none;
  padding: 20px;

  &-overlay {
    position: fixed;
    inset: 0px;
    background-color: rgba(255, 255, 255, 0.75);
    z-index: 1200;
  }

  &-task {
    .form {
      min-width: 650px;
      min-height: 710px;
      display: flex;
      flex-direction: column;
    }

    table {
      max-width: 540px;

      thead {
        th {
          font-size: 14px;
          font-weight: 600;
          line-height: 18px;
          margin-bottom: 10px;
        }
      }
    }
  }
}


.MuiBox-root {
  .MuiTabs-root {
    min-height: 38px;

    .MuiTabs-fixed {
      .MuiTabs-indicator {
        background-color: $color-text-active;
      }

      .MuiButtonBase-root {
        font-size: 0.675rem;
        min-height: 38px;

        &.Mui-selected {
          color: $color-text-active;
        }
      }
    }
  }
}