.btn {
  border-radius: 25px;
  padding: 10px 20px;
  text-transform: uppercase;
  border: 1px solid transparent;
  font-family: $font-primary;

  i {
    font-style: normal;
    font-weight: bold;
    color: $color-branding-light-green;
    padding-right: 10px;
  }
}

.btn-primary {
  @extend .btn;
  background-color: $color-branding-grey;
  color: white;

  &:hover {
    background-color: $color-branding-light-grey;
  }

  .arrow {
    font-style: normal;
    font-weight: bold;
    color: $color-branding-light-green;
    padding-left: 10px;
    padding-right: 0;
  }
}

.btn-secondary {
  @extend .btn;
  background-color: white;
  color: $color-branding-grey;

  &:hover {
    border: 1px solid $color-branding-grey;
  }
}

.btn-ternary {
  @extend .btn;
  background-color: $color-branding-green;
  color: $color-branding-grey;
  font-weight: 600;

  i {
    color: $color-branding-grey;
  }

  &:hover {
    background-color: $color-branding-light-green;
  }
}

.btn-ghost {
  @extend .btn;
  background-color: white;
  border: 1px solid $color-branding-green;

  &:hover {
    background-color: $color-branding-green;
    color: white;
  }
}

.btn-icon {
  border: none;
  background-color: transparent;
  color: $color-branding-light-grey;
}

.MuiCircularProgress-colorPrimary {
  color: $color-branding-light-grey !important;
}

.btn-arrow-right {
  background-image: url(../../../icons/icon-arrow-right-green.svg);
  height: 10px;
  width: 30px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.btn-chevron-down {
  background-image: url(../../../icons/chevron-vert.svg);
  height: 7px;
  width: 12px;
  background-size: cover;
  border: none;
  background-color: transparent;
  background-position: center;
  background-repeat: no-repeat;
  transform: rotate(180deg);

  &.active {
    transform: rotate(0);
  }
}

.btn-min-w {
  min-width: 180px;
}

.btn-plus-icon {
  border: none;
  background-color: transparent;
  color: $color-branding-light-green;
  font-size: 20px;
  transition: all .3s ease-in-out;
  cursor: pointer;
}

.btn-icon-side {
  height: 80px;
  width: 80px;
  background-color: transparent;
  border: none;
  border-radius: 8px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 25px 25px;
  transition: all .3s ease-in-out;
  color: white;
  font-family: $font-primary;
  font-weight: 500;
  padding-top: 35px;
  background-position-y: 15px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  text-transform: capitalize;

  &:hover, &.active {
    background-color: white;
    color: $color-branding-green;
  }
}

.btn-home {
  background-image: url(../../../icons/icon-house-white.svg);

  &:hover, &.active {
    background-image: url(../../../icons/icon-house-green.svg);
  }
}

.btn-events {
  background-image: url(../../../icons/icon-calendar-white.svg);

  &:hover, &.active {
    background-image: url(../../../icons/icon-calendar-green.svg);

  }
}

.btn-task {
  background-image: url(../../../icons/icon-task-white.svg);

  &:hover, &.active {
    background-image: url(../../../icons/icon-task-green.svg);

  }
}

.btn-projects {
  background-image: url(../../../icons/icon-plan-white.svg);

  &:hover, &.active {
    background-image: url(../../../icons/icon-plan-green.svg);

  }
}

.btn-contact {
  background-image: url(../../../icons/icon-contact-white.svg);

  &:hover, &.active {
    background-image: url(../../../icons/icon-contact-green.svg);

  }
}

.btn-timesheet {
  background-image: url(../../../icons/icon-timesheets-white.svg);

  &:hover, &.active {
    background-image: url(../../../icons/icon-timesheets-green.svg);

  }
}

.btn-timeline {
  background-image: url(../../../icons/icon-time-white.svg);

  &:hover, &.active {
    background-image: url(../../../icons/icon-time-green.svg);

  }
}

.btn-invoicing {
  background-image: url(../../../icons/icon-money-white.svg);

  &:hover, &.active {
    background-image: url(../../../icons/icon-money-green.svg);

  }
}

.btn-icon-quick {
  background-color: $color-branding-green;
  border-radius: 50%;
  border: none;
  height: 65px;
  width: 65px;

  &:hover {
    background-color: $color-branding-light-green;
  }
}

.btn-link {
  background-color: transparent;
  border: none;
  color: white;
  font-size: 16px;
  font-weight: 300;
  letter-spacing: -0.2px;

  &:hover {
    color: $color-branding-green;
    text-decoration: underline;
  }
}

.btn-link-inline {
  color: $color-branding-green;
  text-decoration: underline;
}

.btn-link-dark {
  color: $color-grey-dark;
  padding: 0 5px;
  text-decoration: underline;
  background-color: transparent;
  border: none;
  font-weight: bold;

  &.full {
    display: block;
    padding: 0;
    text-decoration: none;
    font-weight: bold;
    font-size: 14px;
    font-family: $font-primary;
    background-color: transparent;
  }

  &.light {
    font-weight: 300;
  }
}

.btn-link-dark-green {
  color: $color-branding-dark-green;
  padding: 0 5px;
  background-color: transparent;
  border: none;
  font-weight: bold;
  font-size: 14px;
  font-family: $font-primary;

  &.full {
    display: block;
    padding: 0;
  }

  &.light {
    font-weight: 300;
  }

  .MuiSvgIcon-root {
    position: relative;
    top: 6px;
  }
}

.btn-link-blue {
  background-color: transparent;
  color: $color-branding-blue;
  border: none;
  font-weight: 300;
  text-decoration: underline;
  font-size: 16px;

  &.full {
    display: block;
  }
}

.btn-group {
  margin: 20px 0;

  button + button {
    margin-left: 20px;
  }
}

.btn-group-right {
  align-self: end;
  margin-left: auto;
  text-align: right;
  display: inline-flex;
  align-items: center;

  button + button, button + .btn-icon, .btn-icon + .btn-icon {
    margin-left: 20px;
  }

  .btn-icon {
    -webkit-appearance: button;
    cursor: pointer;
  }

  &.not-visible {
    display: none;
  }
}

.btn-icon-edit {
  background-color: transparent;
  background-image: url(../../../icons/icon-pen-grey.svg);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border: none;
  height: 15px;
  width: 15px;

  &.blue {
    background-image: url(../../../icons/icon-pen-blue.svg);
  }
}

.btn-icon-delete {
  @extend .btn-icon-edit;
  background-image: url(../../../icons/icon-trash-grey.svg);

  &.blue {
    background-image: url(../../../icons/icon-trash-blue.svg);
  }
}

.btn-close {
  position: relative;
  background-color: transparent;
  border: none;

  &:after {
    content: "\00d7";
    font-size: 28px;
    color: $color-border-dark-blue-secondary;
  }
}

.btn-simple {
  font-size: 14px;
  font-weight: 600;
  letter-spacing: -0.24px;
  line-height: 18px;
  background-color: transparent;
  border: none;
  position: relative;
}

.btn-group-simple {
  .btn-simple + .btn-simple {
    margin-left: 10px;
    padding-left: 10px;

    &:before {
      content: '|';
      left: -5px;
      position: absolute;
    }
  }
}

.btn-icon-fav {
  background-color: transparent;
  background-image: url(../../../icons/icon-heart-empty-green.svg);
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  border: none;
  height: 15px;
  width: 15px;

  &.full {
    background-image: url(../../../icons/icon-heart-full-green.svg);
  }
}

.btn-dots {
  background-color: transparent;
  border: none;
  position: relative;

  &:after {
    position: absolute;
    content: '...';
    color: $color-branding-blue;
    font-weight: 600;
    font-size: 18px;
  }
}

.btn-filter {
  background-image: url(../../../icons/icon-filter-white.svg);
  background-size: 20px;
  background-position: center;
  background-repeat: no-repeat;
  border: none;
  height: 48px;
  width: 48px;
  border-radius: 8px;
  background-color: $color-branding-green;

  &:hover {
    background-color: $color-branding-light-green;
  }
}

.btn-plus-circle {
  position: relative;
  background-color: transparent;
  border: none;
  height: 40px;
  display: flex;
  align-content: center;
  line-height: 40px;
  padding-left: 35px;
  text-indent: 50px;
  font-size: 12px;
  font-weight: 600;
  color: $color-branding-green;

  &:before {
    position: absolute;
    content: '+';
    height: 40px;
    width: 40px;
    background-color: $color-branding-green;
    color: $color-branding-grey;
    border-radius: 50%;
    line-height: 38px;
    text-indent: 0;
    font-size: 18px;
  }
}


.btn-plus-create {
  text-align: left;
  width: 100%;
  padding: 0;
  color: $color-branding-grey;
  line-height: 18px;
  font-weight: 300;
  white-space: nowrap;
  cursor: pointer;
  background-color: transparent;
  border: none;
  outline: none !important;
  margin: 0;
  text-decoration: none;
  display: inline-block;

  &:disabled {
    opacity: .3;
  }

  &:hover {
    color: $color-branding-green;

    .plus-create-icon {
      color: white;
      background-color: $color-branding-green;
    }
  }

  .plus-create-icon {
    align-items: center;
    border-radius: 50%;
    color: $color-branding-green;
    display: inline-flex;
    height: 17px;
    justify-content: center;
    margin-right: 11px;
    -webkit-transform: translateY(8px);
    transform: translateY(8px);
    vertical-align: 7px;
    width: 17px;

    svg {
      height: 0.65em;
      width: 0.65em;
    }
  }
}