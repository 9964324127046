.text-grey {
  font-weight: 600;
  color: $color-grey-dark;
  font-size: 14px;

  a {
    color: inherit;
  }
}


.block-title {
  font-weight: 600;
  margin-bottom: 5px;
  font-size: 14px;
}

.block-content {
  font-size: 14px;
  color: $color-grey-darkest;
  line-height: 20px;
  text-align: left;
}

.bold {
  font-weight: bold;
}

.text-md {
  font-size: 16px;
  font-weight: 300;
  letter-spacing: -0.2px;
  color: white;

  &.bold {
    font-weight: 500;
  }
}

.text-sm-green {
  font-size: 10px;
  font-weight: 600;
  color: $color-branding-light-green;
  line-height: 13px;
  text-transform: uppercase;
}

.text-sm-grey {
  font-size: 10px;
  font-weight: 600;
  line-height: 13px;
  text-transform: uppercase;
  color: $color-grey-dark;
}


.text-small {
  font-size: 70%;
}

.text-lg {
  font-size: 18px;
  font-weight: 500;
  line-height: 23px;
  color: $color-branding-grey-dark;
}

.text-slim {
  font-size: 14px;
  color: $color-branding-grey-dark;
  line-height: 18px;
  font-weight: 300;
}

.text-normal {
  color: $color-branding-grey-dark;
  font-size: 18px;
  font-weight: 600;
  line-height: 23px;
}

.text-bold {
  color: $color-grey-darkest;
  font-size: 14px;
  font-weight: 500;

  &.dark {
    color: $color-grey-dark;
  }

  &.green {
    color: $color-branding-dark-green;
  }
}

.text-subtitle {
  color: $color-branding-blue;
  font-size: 16px;
  font-weight: 600;
  line-height: 23px;

  &.light {
    font-weight: 300;
  }
}

.text-grey-dark {
  color: $color-grey-darkest;
  font-size: 12px;
  padding-bottom: 5px;

  &.bold {
    font-weight: 500;
  }

  &.white {
    color: white;
  }

  &.blue {
    color: $color-branding-blue;
  }

  &.light {
    font-weight: 300;
  }
}

.text-camelcase {
  text-transform: none;
  font-size: 12px;
}

.text-normal {
  font-size: 14px;
  line-height: 20px;
  color: $color-grey-darkest;
  font-weight: 400;
}

.text-blue {
  font-size: 14px;
  line-height: 20px;
  color: $color-branding-blue;
  font-weight: 500;
}

.label-component {
  font-size: 14px;
  font-weight: 600;
  line-height: 18px;
  margin-bottom: 10px;
  display: inline-block;
}

.text-simple {
  font-size: 18px;
  font-weight: 600;
  line-height: 32px;
  text-transform: uppercase;
  padding-right: 1rem;
  color: $color-branding-blue;
}

.text-green-dark {
  color: $color-branding-dark-green;
  font-size: 18px;
  font-weight: 600;
}

.text-muted {
  --bs-text-opacity: 1;
  color: #98a6ad !important;
}

.form-body {
  .text-muted {
    font-size: 55%;
  }
}

.text-dark-green {
  font-size: 14px;
  line-height: 20px;
  color: $color-branding-dark-green !important;
  font-weight: 500;
}
