// COLOR

/* Branding */

$color-branding-light-green: #E8FF00;
$color-branding-light-grey: #3E4C4F;
$color-branding-second-light-grey: #C0CDD3;
$color-branding-green: #CCE004;
$color-brand-green-bright: #F6F9D6;
$color-branding-grey: #2C3A3E;
$color-branding-grey-dark: #1E2617;
$color-branding-dark-green: #A7B800;
$color-branding-blue: #004B4F;
$color-branding-blue-light: #006BB2;
$color-branding-blue-lightest: #7EA6B1;

/* Font Colors */

$color-font: #000;
$color-font-medium: #575757;
$color-font-light: #909090;

/* Other Colors */
$color-grey: #f6f6f6;
$color-grey-dark: #5E909D;
$color-grey-darkest: #3D483C;
$color-stroke: #e5e5e5;
$color-background-grey: #F2F2F2;
$color-background-grey-secondary: #EAF2F5;
$color-background-grey-ternary: #D8E5EA;
$color-border: #D6D6D6;
$color-border-green: #CEE0C8;
$color-border-blue: #BBCCD2;
$color-border-dark-blue: #C0CED2;
$color-border-dark-blue-secondary: #c0cdd2;
$color-message-success: #94A301;
$color-background-message-success: #eefd53;
$color-message-error: #D85600;
$color-background-message-error: #ffa66b;
$color-background-message-warning: #2C3A3E;
$color-background-message-info: #8caa73;
$color-text-active: #B5C700;

// SIZE

/* Spacings */

$size-logo: 116px;
$size-header: 80px;
$size-header-stripe: 8px;
$size-spacing: 8px;
$sidebar-width: 92px;

/* Widths */

$size-content: 1024px + ($size-spacing * 8);


// BREAKPOINTS

$breakpoint-desktop-small: 1300px;
$breakpoint-tablet: 1050px;
$breakpoint-tablet-portrait: 768px;
$breakpoint-mobile: 480px;
$breakpoint-mobile-s: 310px;

@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:wght@300;400;500;600;700&display=swap');

$font-primary: 'IBM Plex Sans', sans-serif;