.container-date {
  position: relative;

  &:after {
    position: absolute;
    content: "";
    background-image: url(../../../icons/icon-calendar-green.svg);
    width: 20px;
    height: 20px;
    left: 10px;
    top: 50%;
    transform: translateY(-50%);
  }

  input {
    padding-left: 40px;
  }

  .react-datepicker__close-icon::after {
    background-color: $color-branding-green;
  }
}

.container-time {
  @extend .container-date;

  &:after {
    background-image: url(../../../icons/icon-time-green.svg);
  }

  &:before {
    position: absolute;
    content: "h";
    width: 20px;
    height: 20px;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
  }

  input {
    padding-right: 40px;
  }
}