.badge {
  background-color: white;
  border-radius: 20px;
  color: $color-grey-dark;
  text-transform: uppercase;
  padding: 8px;
  text-align: center;
  font-size: 11px;
  font-weight: 600;

  &-secondary {
    @extend .badge;
    background-color: $color-background-grey-secondary;
  }

  &-secondary {
    @extend .badge;
    background-color: $color-background-grey-secondary;
  }

  &-success {
    @extend .badge;
    background-color: $color-background-message-success;
    color: $color-message-success;
  }

  &-danger {
    @extend .badge;
    background-color: $color-background-message-error;
    color: $color-message-error;
  }

  &-warning {
    @extend .badge;
    background-color: $color-background-message-warning;
    color: white;
  }

  &-info {
    @extend .badge;
    background-color: $color-background-message-info;
    color: $color-branding-grey-dark;
  }
}

.badge-ternary {
  @extend .badge;
  font-weight: bold;
  color: $color-branding-grey-dark;
}